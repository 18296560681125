import * as R from 'ramda';
import FileSaver from 'file-saver';
import FileDownload from 'js-file-download';
// constants
import * as GC from '../constants';
// icons
import * as I from '../svgs';
// helpers
import * as H from './helpers';
//////////////////////////////////////////////////

const openFile = (file: any) => {
  const link = document.createElement('a');

  link.target = '_blank';
  link.href = window.URL.createObjectURL(file);

  link.click();
  link.remove();
};

const makeDataForDocument = (payload: Object) => {
  const data = new window.FormData();

  data.append('file', payload.file);
  data.append('data', JSON.stringify(payload));

  return data;
};

const makeDataForMultipleDocuments = (payload: Object) => {
  const data = new window.FormData();

  const { files } = payload;

  if (H.isNotNilAndNotEmpty(files)) {
    payload.files.forEach((file: any) => data.append('files', file));
  }

  data.append('data', JSON.stringify(payload));

  data.append('deleteFile', R.propOr(false, 'deleteFile', payload));

  return data;
};

const downloadFile = (data: Object, name: string) => FileDownload(data, name);

const downloadFileFromUrl = (url: string, fileName: string) => {
  const tempLink = document.createElement('a');

  tempLink.href = url;
  tempLink.download = fileName;
  tempLink.style.display = 'none';

  // Safari thinks _blank anchor are pop ups. We only want to set _blank
  // target if the browser does not support the HTML5 download attribute.
  // This allows you to download files in desktop safari if pop up blocking
  // is enabled.
  if (typeof tempLink.download === 'undefined') {
    tempLink.setAttribute('target', '_blank');
  }

  document.body.appendChild(tempLink);

  tempLink.click();

  document.body.removeChild(tempLink);
};

const getPrintDocumentOptions = R.compose(
  R.map(({ guid, documentFilename }: Object) => ({
    [GC.FIELD_VALUE]: guid,
    [GC.FIELD_LABEL]: documentFilename,
  })),
  R.filter((document: Object) => {
    const fileExtension = R.last(R.split('.', R.pathOr('', [GC.FIELD_DOCUMENT_FILE_NAME], document)));

    return R.includes(fileExtension, GC.DOCUMENTS_EXTENSIONS);
  }),
);

const getFileNameFromResponseDispositionHeader = (request: Object, defaultFileName: string) => {
  if (H.isNilOrEmpty(request)) return defaultFileName;

  let filename = defaultFileName;

  const disposition = request.getResponseHeader('content-disposition');

  const condition = R.and(
    H.isNotNilAndNotEmpty(disposition),
    H.notEquals(R.indexOf('attachment', disposition), -1),
  );

  if (condition) {
    const matches = GC.FILE_NAME_REGEXP.exec(disposition);

    if (H.isNotNil(R.path([1], matches))) {
      filename = R.path([1], matches).replace(/['"]/g, '');
    }
  }

  return filename;
};

const saveFileFromResponse = (response: Object, defaultFileName: string = null) => {
  const filename = getFileNameFromResponseDispositionHeader(response.request, defaultFileName);

  const type = R.pathOr('application/pdf', ['headers', 'content-type'], response);

  const file = new window.Blob(R.of(Array, response.data), { type });

  FileSaver.saveAs(file, filename);
};

const openFileInWindowFromArrayBufferResponse = (res: Object, options: Object) => {
  const { data, headers, request } = res;

  const defaultFileName = R.path(['defaultFileName'], options);
  const sizes = R.pathOr('width:200,height:200', ['sizes'], options);
  const type = R.pathOr('application/octet-stream', ['content-type'], headers);

  const fileName = getFileNameFromResponseDispositionHeader(request, defaultFileName);

  const blob = new Blob([data], { type }); // eslint-disable-line
  const blobURL = window.URL.createObjectURL(blob);

  window.open(blobURL, R.or(fileName, 'Preview'), sizes);
  window.URL.revokeObjectURL(blobURL);
};

const getImageSrcFromArrayBufferResponse = (res: Object) => {
  const { data, headers } = res;

  const type = R.pathOr('application/octet-stream', ['content-type'], headers);

  const blob = new Blob([data], { type }); // eslint-disable-line

  return window.URL.createObjectURL(blob);
};

// array-buffer
const decodeArrayBuffer = (file: ArrayBuffer) => {
  if (R.not('TextDecoder' in window)) {
    return 'Sorry, this browser does not support TextDecoder...';
  }

  const decoder = new TextDecoder('utf-8'); // eslint-disable-line

  const text = decoder.decode(file);

  return R.map(R.trim, R.split('~', text));
};

const convertArrayBufferFailResponse = (res: Object) => {
  const decodedString = String.fromCharCode.apply(null, new Uint8Array(res.data));

  const obj = JSON.parse(decodedString);

  const { message } = obj;

  return R.assoc('data', { message }, res);
};

export {
  openFile,
  downloadFile,
  decodeArrayBuffer,
  downloadFileFromUrl,
  makeDataForDocument,
  saveFileFromResponse,
  getPrintDocumentOptions,
  makeDataForMultipleDocuments,
  convertArrayBufferFailResponse,
  getImageSrcFromArrayBufferResponse,
  openFileInWindowFromArrayBufferResponse,
  getFileNameFromResponseDispositionHeader,
};
