// helpers
import { getWindowLocale } from './locale';
//////////////////////////////////////////////////

const createCommonModalOptions = (modalContent: Object) => ({
  p: '0',
  component: modalContent,
  options: { width: 'auto', height: 'auto' },
});

const createAddReferenceModalOptions = (modalContent: Object) => ({
  p: 15,
  component: modalContent,
  options: {
    width: 300,
    height: 'auto',
    title: getWindowLocale('titles:add-reference', 'Add Reference'),
  },
});

const getDefaultModalOptions = (
  component: Object,
  title: string,
) => ({
  p: 15,
  component,
  options: {
    title,
    width: 300,
    height: 'auto',
  },
});

const getDefaultModalOptions2 = (
  component: Object,
  title: string,
) => ({
  p: 15,
  component,
  options: {
    title,
    width: 445,
    height: 'auto',
  },
});

const getRateModalWithContent = (component: any) => ({
  p: '0',
  component,
  options: {
    height: 'auto',
    overflow: 'auto',
    maxWidth: '95vw',
    maxHeight: '90vh',
    boxShadow: 'none',
    backgroundColor: 'none',
  },
});

const getConfirmDeleteModal = (component: any, action: Function) => ({
  component,
  options: {
    width: 600,
    controlButtons: [
      {
        action,
        type: 'button',
        name: getWindowLocale('actions:delete', 'Delete'),
      },
    ],
  },
});

const getFullWindowModalWithContent = (component: any) => ({
  p: '0',
  component,
  options: {
    width: '100vw',
    height: '100vh',
  },
});

const getFullWindowModalWithContentWithLeftSpace = (component: any, leftSpaceWidth: number) => ({
  p: '0',
  component,
  options: {
    height: '100vh',
    left: leftSpaceWidth || 'initial',
    width: `calc(100vw - ${leftSpaceWidth || 0}px)`,
  },
});

export {
  getConfirmDeleteModal,
  getDefaultModalOptions,
  getDefaultModalOptions2,
  getRateModalWithContent,
  createCommonModalOptions,
  getFullWindowModalWithContent,
  createAddReferenceModalOptions,
  getFullWindowModalWithContentWithLeftSpace,
};
