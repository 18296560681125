import * as R from 'ramda';
// common
import { menuRoutesConfig } from '../common/settings';
// helpers
import { getCurrentPathname } from './route';
import { isNotNilAndNotEmpty } from './helpers';
//////////////////////////////////////////////////

const getCurrentMenuItem = () => {
  const path = getCurrentPathname();

  let currentItem = '';

  if (isNotNilAndNotEmpty(path)) {
    R.forEachObjIndexed(
      (config: Object, key: string) => {
        const isRegExp = R.any((regExp: RegExp) => regExp.test(path), config.regExps);
        const isPath = R.any((configPath: string) => R.equals(configPath, path), config.paths);

        if (R.or(isRegExp, isPath)) {
          currentItem = key;
        }
      },
      menuRoutesConfig,
    );
  }

  return currentItem;
};

export {
  getCurrentMenuItem,
};
